<template>
  {{ opDescription }}
  <InPlaceEdit
    v-if="isEditing"
    title="Substring matches (one value per line)"
    @cancel="stopEditing"
    @commit="commit"
    :valid="isValid"
  >
    <textarea
      v-model="valueUnderEdit"
      ref="valueInputEl"
      class="w-full border-transparent bg-transparent"
    ></textarea>

    <Select
      v-model="matchValue"
      :options="['contain', 'start', 'end', 'full']"
      :clearable="false"
    />
    <Checkbox label="Ignore case?" v-model="caseInsensitiveValue" />
  </InPlaceEdit>
  <span
    v-else
    class="cursor-pointer border-b border-dotted border-b-transparent font-bold hover:border-b-orange"
    @click="startEditing"
    >{{ valueList }}</span
  >
</template>

<script setup lang="ts">
import InPlaceEdit from "@/common/components/InPlaceEdit.vue";
import { stringifyValue, toValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import Select from "@/common/components/Select.vue";
import Checkbox from "@/common/components/Checkbox.vue";
import { QueryFilter } from "@/common/lib/query";
import { TextFilter } from "@/common/lib/fetchApi";

const props = defineProps<{ filter: QueryFilter<TextFilter> }>();
const { filter } = toRefs(props);

const valueUnderEdit: Ref<string | null> = ref(null);
const valueInputEl: Ref<HTMLInputElement | null> = ref(null);
const matchValue = ref<"contain" | "start" | "end" | "full">("full");
const caseInsensitiveValue = ref(true);

const opDescription = computed(function () {
  const negated = filter.value.negated;
  let desc = "";
  switch (matchValue.value) {
    case "contain":
      desc = negated ? "doesn't contain" : "contains";
      break;
    case "start":
      desc = negated ? "doesn't start with" : "starts with";
      break;
    case "end":
      desc = negated ? "doesn't end with" : "ends with";
      break;
    case "full":
      desc = negated ? "is not" : "is";
      break;
  }
  if (filter.value.values.length > 1 || isEditing.value) {
    desc = desc + " any of";
  }
  return desc;
});
const isEditing = computed(() => valueUnderEdit.value !== null);
const valueList = computed(() =>
  filter.value.values.length === 0
    ? "(click to set)"
    : filter.value.values.map((v) => stringifyValue(v.value)).join(", ")
);
const isValid = computed(() => cleanEditedValue().length > 0);

const exploreStore = useExploreStore();

function startEditing() {
  if (!isEditing.value)
    valueUnderEdit.value = filter.value.values.map((f) => f.value.value).join("\n");
}

function cleanEditedValue() {
  return valueUnderEdit
    .value!.split("\n")
    .map((v) => v.trim())
    .filter((f) => f.length > 0);
}

function commit() {
  filter.value.values = cleanEditedValue().map((v) => ({
    value: toValue(v),
    match: matchValue.value,
    case_sensitive: !caseInsensitiveValue.value,
  }));
  stopEditing();
  exploreStore.load();
}

function stopEditing() {
  valueUnderEdit.value = null;
}

onMounted(function () {
  if (filter.value.values.length === 0) {
    startEditing();
    nextTick(() => valueInputEl.value?.focus());
  }
});
</script>
