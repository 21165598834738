import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex max-w-[450px] flex-col items-start gap-10 self-stretch px-10 py-0" }
const _hoisted_2 = { class: "item-start flex justify-between gap-10 self-stretch text-lg text-gray-900 dark:text-gray-100" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "flex flex-col items-start gap-10 self-stretch" }
const _hoisted_5 = { class: "flex items-center justify-end gap-10 self-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, [
          _createTextVNode(" Follow the instructions in the "),
          _createElementVNode("a", {
            class: "text-inherit visited:text-inherit",
            href: $setup.databricksOAuthDocsUrl,
            target: "_blank"
          }, "Databricks Documentation", 8 /* PROPS */, _hoisted_3),
          _createTextVNode(" to create a personal access token and paste it along with your Workspace URL below to connect your account. ")
        ])
      ]),
      _createElementVNode("form", _hoisted_4, [
        _createVNode($setup["Textbox"], {
          label: "Workspace nickname",
          required: true,
          modelValue: $setup.workspace.metadata.nickname,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.workspace.metadata.nickname) = $event)),
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Personal access token",
          required: true,
          modelValue: $setup.workspace.credentials.personal_access_token,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.workspace.credentials.personal_access_token) = $event)),
          type: "password",
          autocomplete: "on",
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"]),
        _createVNode($setup["Textbox"], {
          label: "Server hostname",
          required: true,
          modelValue: $setup.workspace.metadata.id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.workspace.metadata.id) = $event)),
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["modelValue", "disabled"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode($setup["TextButton"], {
          label: "Cancel",
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.cancelEditWorkspace())),
          disabled: $setup.saving
        }, null, 8 /* PROPS */, ["disabled"]),
        (!$setup.saving)
          ? (_openBlock(), _createBlock($setup["TextButton"], {
              key: 0,
              label: $setup.commitLabel,
              disabled: !$setup.saveEnabled || $setup.saving,
              onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.saveWorkspace()))
            }, null, 8 /* PROPS */, ["label", "disabled"]))
          : (_openBlock(), _createBlock($setup["SpinnerButton"], {
              key: 1,
              label: $setup.commitLabel
            }, null, 8 /* PROPS */, ["label"]))
      ])
    ]),
    ($setup.errorDialogMessage)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          "cancel-label": undefined,
          title: "Connection Error",
          icon: "error",
          onSucceeded: _cache[5] || (_cache[5] = ($event: any) => ($setup.errorDialogMessage = undefined))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.errorDialogMessage), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}