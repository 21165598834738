import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["inline-flex cursor-pointer items-center justify-center gap-5 text-center", [
      `text-${$setup.textColor}`,
      {
        'active:shadow-none hover:bg-gray-200 hover:shadow-light active:bg-gray-100 dark:hover:bg-gray-900 dark:active:bg-black':
          !($props.disabled || $props.darkHover),
        'border-none text-lg uppercase': !$setup.icon && !$props.pill,
        'border border-solid text-base normal-case': $setup.icon,
        'hover:shadow-none hover:text-gray-700 dark:hover:text-white dark:active:border-black':
          $setup.icon && !$props.disabled,
        'border-gray-200 dark:border-gray-900': !$props.selected && $setup.icon,
        'bg-transparent': !$props.selected && !$props.pill,
        'shadow-none border-orange bg-orange hover:bg-orange hover:text-white': $props.selected,
        'active:bg-gray hover:bg-black': $props.darkHover && !$props.disabled,
        'hover:bg-yellow-700 active:bg-yellow-900': $props.mode === 'warning' && !$props.disabled,
        'hover:bg-red-700 active:bg-red-900': $props.mode == 'error' && !$props.disabled,
        'gap-10': $setup.menu,
        'cursor-not-allowed opacity-100': $props.disabled,
        'rounded px-[6px] py-[4px]': !$props.pill,
        'rounded-[20px] p-5': $props.pill,
        'bg-gray-200 dark:bg-gray-900': $props.pill && !$props.disabled,
      },
    ]]),
    disabled: $props.disabled
  }, [
    ($setup.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["pointer-events-none block", {
        'svg-to-orange': !($props.disabled || $props.selected),
        'svg-to-dark-gray': $setup.darkMode && $props.disabled,
        'svg-to-light-gray': !$setup.darkMode && $props.disabled,
        'svg-to-black active:svg-to-orange': $setup.darkMode && $props.selected,
        'svg-to-dark-gray active:svg-to-light-gray': !$setup.darkMode && $props.selected,
      }]),
          src: $setup.iconSrc,
          width: $setup.sizePx,
          height: $setup.sizePx
        }, null, 10 /* CLASS, PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createTextVNode(" " + _toDisplayString($props.label), 1 /* TEXT */)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}