import { createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between" }
const _hoisted_2 = { class: "flex flex-row items-center gap-10" }
const _hoisted_3 = {
  key: 0,
  class: "text-gray-700 dark:text-gray-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Select"], {
    label: $props.label,
    options: $setup.concepts,
    modelValue: $setup.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
    "option-value": $setup.knowledgeItemId,
    placeholder: $props.placeholder,
    clearable: false
  }, {
    "option-data": _withCtx((concept) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "rounded-full p-[3px]",
            style: _normalizeStyle({ backgroundColor: $setup.color($setup.knowledgeItemId(concept)) })
          }, [
            _createVNode($setup["Icon"], {
              name: concept.icon_name,
              color: "gray2",
              size: "m"
            }, null, 8 /* PROPS */, ["name"])
          ], 4 /* STYLE */),
          _createTextVNode(" " + _toDisplayString(concept.label), 1 /* TEXT */)
        ]),
        ($props.includeConceptId)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.knowledgeItemId(concept)), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label", "options", "modelValue", "option-value", "placeholder"]))
}