<template>
  <div class="ml-[22px] mr-5 flex items-center rounded-xl bg-gray-200 px-5 py-2 dark:bg-gray-900">
    <Icon name="md:filter_alt" color="orange" class="self-start" />
    <div class="mr-5 basis-full overflow-hidden">
      <EqualityFilterComponent
        :filter="filter as QueryFilter<EqualityFilter>"
        v-if="filter.type === FilterType.Equality"
      />
      <TextFilterComponent
        :filter="filter as QueryFilter<TextFilter>"
        v-if="filter.type === FilterType.Text"
      />
      <RangeFilterComponent
        :filter="filter as QueryFilter<RangeFilter>"
        v-if="filter.type === FilterType.Range"
      />
      <ExistenceFilterComponent
        :filter="filter as QueryFilter<ExistenceFilter>"
        v-if="filter.type === FilterType.Exists"
      />
    </div>
    <Dropdown placement="right" class="body">
      <template #popper>
        <Menu :items="menuItems" v-close-popper />
      </template>
      <IconButton name="chevron-down" size="xs" />
    </Dropdown>
  </div>
</template>

<script setup lang="ts">
import IconButton from "@/common/components/IconButton.vue";
import { useExploreStore } from "@/reader/stores/explore";
import { ComputedRef, computed, toRefs } from "vue";
import EqualityFilterComponent from "./filter-types/EqualityFilter.vue";
import TextFilterComponent from "./filter-types/TextFilter.vue";
import RangeFilterComponent from "./filter-types/RangeFilter.vue";
import ExistenceFilterComponent from "./filter-types/ExistenceFilter.vue";
import { MenuItem } from "@/common/lib/menus";
import { Dropdown } from "floating-vue";
import Menu from "@/common/components/Menu.vue";
import Icon from "@/common/components/Icon.vue";
import { QueryFilter } from "@/common/lib/query";
import {
  FilterType,
  ExistenceFilter,
  EqualityFilter,
  RangeFilter,
  TextFilter,
} from "@/common/lib/fetchApi";

const props = defineProps<{ filter: QueryFilter }>();
const { filter } = toRefs(props);

const exploreStore = useExploreStore();

const menuItems: ComputedRef<MenuItem[]> = computed(() => [
  {
    key: "negate",
    label: "Negate filter",
    action: () => exploreStore.toggleFilterNegated(filter.value.alias),
  },
  {
    key: "remove",
    label: "Remove filter",
    action: () => exploreStore.removeFilter(filter.value.alias),
  },
]);
</script>
