<template>
  <template v-if="isEditing">
    {{ opDescription }} between
    <InPlaceEdit
      v-if="isEditing"
      title="Value Range"
      @cancel="stopEditing"
      @commit="commit"
      :valid="isEditValid"
    >
      <input
        :type="filterType"
        v-model="lowerBoundUnderEdit"
        ref="lowerBoundInputEl"
        placeholder="Enter a minimum"
        class="w-full border-transparent bg-transparent"
        :style="{ colorScheme: 'dark' }"
      />
      <input
        :type="filterType"
        v-model="upperBoundUnderEdit"
        placeholder="Enter a maximum"
        class="w-full border-transparent bg-transparent"
        :style="{ colorScheme: 'dark' }"
      />
    </InPlaceEdit>
  </template>
  <span
    v-else
    @click="startEditing"
    class="cursor-pointer border-b border-dotted border-b-transparent hover:border-b-orange"
  >
    {{ opDescription }}
    <template v-if="filter.values.length > 0">
      <template v-if="isExact">
        exactly
        <span class="font-bold">{{ stringifyValue(lowerBound!) }}</span>
      </template>
      <template v-else>
        <template v-if="upperBound == null">
          at least
          <span class="font-bold">{{ stringifyValue(lowerBound!) }}</span>
        </template>
        <template v-else-if="lowerBound == null">
          at most
          <span class="font-bold">{{ stringifyValue(upperBound) }}</span>
        </template>
        <template v-else>
          between
          <span class="font-bold">{{ stringifyValue(lowerBound) }}</span>
          and
          <span class="font-bold">{{ stringifyValue(upperBound) }}</span>
        </template>
      </template>
    </template>
    <template v-else>(click to set)</template>
  </span>
</template>

<script setup lang="ts">
import InPlaceEdit from "@/common/components/InPlaceEdit.vue";
import { toValue, stringifyValue } from "@/common/lib/value";
import { useExploreStore } from "@/reader/stores/explore";
import { isNumber, isString } from "lodash";
import { computed, nextTick, onMounted, Ref, ref, toRefs } from "vue";
import { GraphValueType } from "@/common/lib/value";
import { propertyValueType } from "@/common/lib/derived";
import { QueryFilter } from "@/common/lib/query";
import { RangeFilter } from "@/common/lib/fetchApi";

const props = defineProps<{ filter: QueryFilter<RangeFilter> }>();
const { filter } = toRefs(props);

const lowerBoundUnderEdit: Ref<number | string | "" | null> = ref(null);
const upperBoundUnderEdit: Ref<number | string | "" | null> = ref(null);
const lowerBoundInputEl: Ref<HTMLInputElement | null> = ref(null);

const opDescription = computed(() => (filter.value.negated ? "is not" : "is"));
const isEditing = computed(() => lowerBoundUnderEdit.value !== null);

const exploreStore = useExploreStore();

const filterType = computed(() => {
  switch (propertyValueType(filter.value.property_type)) {
    case "date":
      return "date";
    case "datetime":
      return "datetime-local";
    default:
      return "number";
  }
});

function startEditing() {
  if (!isEditing.value) {
    if (lowerBound.value == null) {
      lowerBoundUnderEdit.value = "";
    } else if (isDate.value) {
      lowerBoundUnderEdit.value = lowerBound.value.value as string;
    } else {
      lowerBoundUnderEdit.value = lowerBound.value.value as number;
    }
    if (upperBound.value == null) {
      upperBoundUnderEdit.value = "";
    } else if (isDate.value) {
      upperBoundUnderEdit.value = upperBound.value.value as string;
    } else {
      upperBoundUnderEdit.value = upperBound.value.value as number;
    }
  }
}

function commit() {
  const rangeFilter: Partial<RangeFilter> = {};
  if (isNumber(lowerBoundUnderEdit.value)) rangeFilter.gte = toValue(lowerBoundUnderEdit.value);
  if (isNumber(upperBoundUnderEdit.value)) rangeFilter.lte = toValue(upperBoundUnderEdit.value);
  if (isString(lowerBoundUnderEdit.value) && lowerBoundUnderEdit.value !== "")
    rangeFilter.gte = {
      _type: filterType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: lowerBoundUnderEdit.value,
    };
  if (isString(upperBoundUnderEdit.value) && upperBoundUnderEdit.value !== "")
    rangeFilter.lte = {
      _type: filterType.value === "date" ? GraphValueType.Date : GraphValueType.Datetime,
      value: upperBoundUnderEdit.value,
    };
  filter.value.values = [rangeFilter];
  stopEditing();
  exploreStore.load();
}

function stopEditing() {
  lowerBoundUnderEdit.value = null;
  upperBoundUnderEdit.value = null;
}

onMounted(function () {
  if (filter.value.values.length === 0) {
    startEditing();
    nextTick(() => lowerBoundInputEl.value?.focus());
  }
});

const lowerBound = computed(() => filter.value.values[0]?.gte ?? filter.value.values[0]?.gt);
const upperBound = computed(() => filter.value.values[0]?.lte ?? filter.value.values[0]?.lt);
const isExact = computed(() => lowerBound.value?.value === upperBound.value?.value);
const isEditValid = computed(function () {
  if (!isDate.value) {
    // At least one bound must be present
    if (!isNumber(lowerBoundUnderEdit.value) && !isNumber(upperBoundUnderEdit.value)) return false;
  } else {
    // At least one bound must be present
    if (!lowerBoundUnderEdit.value && !upperBoundUnderEdit.value) return false;
  }
  // If both bounds are present, bounds must not be inverted
  if (
    lowerBoundUnderEdit.value &&
    upperBoundUnderEdit.value &&
    lowerBoundUnderEdit.value > upperBoundUnderEdit.value
  )
    return false;
  return true;
});
const isDate = computed(() =>
  ["date", "datetime"].includes(propertyValueType(filter.value.property_type))
);
</script>
